import { css } from '@emotion/core';

import { layout } from './layout.styles';
import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  globalRadial,
  lineHeight,
  unit,
} from './vars.styles';

const page = css`
  ${layout.wrapper}
  margin-bottom: 100px;
`;

const step2Page = css`
  ${layout.wrapper}
  margin-top: 90px;

  ${mqFrom.Med} {
    margin-top: 190px;
  }
`;

const step2PageNoEmailVerificationMessage = css`
  ${layout.wrapper}
  margin-top: 120px;
  margin-bottom: 100px;
`;

const pageNoEmailVerificationMessage = css`
  ${layout.wrapper}
  margin-top: 24px;
  margin-bottom: 100px;
`;

const pageTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  ${typescale.medium};

  ${mqFrom.Med} {
    ${typescale.large};
  }

  ${mqFrom.Lrg} {
    ${typescale.xlarge};
  }

  color: ${colors.dark};
`;

const subtitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  font-size: ${fontSize.medium}px;
  line-height: ${lineHeight.medium}px;

  color: ${colors.dark};

  padding-bottom: 18px;

  ${mqFrom.Med} {
    padding-bottom: 0;
  }
`;

const infoSection = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mqFrom.Med} {
    flex-direction: row;

    & > div:first-of-type {
      flex: 1;
      max-width: 30%;
      border: none;
      padding: 0;
    }
  }
`;

const deliveryInputs = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${mqFrom.Med} {
    flex-direction: row;
    & > div {
      max-width: 440px;
    }
  }
`;

const infoInputs = css`
  padding-top: 20px;
  width: 100%;
  flex: 1;
  border-top: 2px solid rgba(0, 0, 0, 0.1);

  ${mqFrom.Med} {
    max-width: 660px;
    border-top: 0;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    padding-left: 40px;
  }

  & > div:last-of-type {
    margin-bottom: 0;
  }
`;

const infoRowFlex = css`
  display: flex;
  flex-direction: column;
  & > div {
    width: 100%;
  }

  ${mqFrom.XSml} {
    flex-direction: row;
    justify-content: space-between;

    & > div {
      width: 45%;
    }
  }
`;

const infoCta = css`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${mqFrom.XSml} {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  ${mqFrom.Med} {
    width: 70%;
    padding-left: 40px;
  }
`;

const distributorPage = css`
  ${layout.wrapper}
  margin-top: 10px;
  margin-bottom: 10px;
`;

const distributors = css`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mqFrom.Med} {
    flex-direction: row;
  }
`;

const distributorHeader = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding-bottom: ${unit * 3}px;
  padding-left: 20px;
  padding-right: 20px;

  ${mqFrom.Med} {
    flex-direction: row;
    justify-content: space-between;
    align-items: end;

    padding-bottom: ${unit * 9}px;
    padding-left: 0;
    padding-right: 0;
  }
`;

const distributorsAside = css`
  flex: 1;
  width: 80%;
  align-self: center;

  ${mqFrom.Med} {
    width: 100%;
    min-height: 550px;
    align-self: flex-start;
  }
`;

const distributorList = css`
  margin-top: ${unit * 5}px;
  max-height: 855px;
  overflow: scroll;

  h4 {
    margin-bottom: ${unit * 10}px;
  }

  li {
    margin-bottom: ${unit * 2}px;
  }
`;

const distributorListItem = css`
  padding: 8px 0;
  border-radius: ${globalRadial}px;
  transition: background-color 0.25s ease-out;
`;

const distributorListItemHover = css`
  ${distributorListItem}

  background-color: ${color(colors.primary, 0.05)};
`;

const ctaContainer = css`
  max-width: 100%;
`;

const successWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 560px;
  margin-top: 100px;
  text-align: center;

  padding: 10px 10px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  transition: all 0.5s;

  ${mqFrom.Med} {
    padding: 20px 40px;
  }

  ${mqFrom.Lrg} {
    padding: 25px 80px;
  }
`;

const successTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};

  font-size: ${fontSize.xlarge}px;
  line-height: ${lineHeight.xlarge}px;

  padding-top: ${unit * 9}px;

  color: ${colors.textSecondary};
`;

const successText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};

  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  padding-top: ${unit * 9}px;

  color: ${colors.textSecondary};
`;

const successOfferText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};

  font-size: ${fontSize.large}px;
  line-height: ${lineHeight.large}px;

  padding-top: ${unit * 2}px;
  padding-bottom: ${unit * 10}px;

  color: ${colors.textSecondary};
`;

const successOfferNumber = css`
  color: ${colors.primary};
  font-weight: ${fontWeights.weightBold};
`;

const mapText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  margin: 0 ${unit}px;
  color: ${colors.primary};

  &:hover {
    text-decoration: underline;
  }
`;

const stepText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;

  color: ${colors.textSecondary};
  opacity: 0.5;
`;

const fullBar = css`
  width: 100%;
  height: 2px;
  background-color: ${colors.primary};
`;

const bar = css`
  width: 100%;
  height: 2px;
  background-color: ${colors.textSecondary};
  opacity: 0.1;
`;

const barWrapper = css`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-top: ${unit * 2}px;
  padding-bottom: ${unit * 10}px;
`;

const checkboxText = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};

  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  color: ${colors.textSecondary};
`;

const declineButton = css`
  color: ${colors.primary};
  padding: 0 ${unit * 16}px;
  line-height: 48px;
  background: transparent;
  color: ${colors.primary};
  border: 1px solid ${colors.primary};

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.25)};
  }
`;

const info = css`
  margin-bottom: 0;
  padding: ${unit * 2}px ${unit * 4}px;
  margin-top: ${unit * 3}px;

  ${mqFrom.XSml} {
    margin-top: 0;
  }
`;

const distributorTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.base}px;
  line-height: ${lineHeight.base}px;

  color: ${colors.textSecondary};
`;

const distributorDescription = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  color: ${colors.textSecondary};
  opacity: 0.6;

  padding-left: 38px;
`;

const infoWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: ${unit * 8}px 0;
`;

const redLine = css`
  width: 6px;
  margin-right: 10px;
  background-color: ${colors.primary};
`;

const infoText = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;

  color: ${colors.textSecondary};
  opacity: 0.8;
`;

const mapButton = css`
  padding-bottom: ${unit * 6}px;

  ${mqFrom.Med} {
    padding-bottom: 0;
  }
`;

const mapWrapper = css`
  display: flex;
  flex-direction: column;

  ${mqFrom.Med} {
    flex-direction: row;
  }
`;

const buttonWrapper = css`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;

  ${mqFrom.Med} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const distributorLogo = css`
  max-width: 100%;
  max-height: 50px;
  margin-bottom: 18px;
  margin-top: -10px;
`;

const checkboxTitle = css`
  margin-bottom: 4px;
`;

const stepTitle = css`
  display: flex;
  flex-direction: row;
`;

const step = css`
  text-transform: uppercase;

  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.tiny};

  color: ${colors.primary};
  white-space: nowrap;
  padding: 0 ${unit * 2}px;
  padding-bottom: ${unit * 3}px;
`;

const distributorHeaderWrapper = css`
  width: 100%;
  ${mqFrom.Med} {
    width: 40%;
  }
`;

const chooseDistributorInfo = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.small};

  color: ${colors.textSecondary};
  padding-top: 26px;
  padding-bottom: 26px;

  ${mqFrom.Med} {
    padding-bottom: 0;
  }
`;

export const cart = {
  page,
  pageTitle,
  infoSection,
  deliveryInputs,
  checkboxTitle,
  infoInputs,
  infoRowFlex,
  infoCta,
  distributorPage,
  distributors,
  distributorHeader,
  distributorsAside,
  distributorList,
  distributorListItem,
  distributorListItemHover,
  distributorTitle,
  distributorDescription,
  ctaContainer,
  successWrapper,
  successTitle,
  successText,
  successOfferText,
  successOfferNumber,
  mapText,
  stepText,
  fullBar,
  bar,
  barWrapper,
  subtitle,
  checkboxText,
  declineButton,
  info,
  infoWrapper,
  infoText,
  redLine,
  mapButton,
  mapWrapper,
  buttonWrapper,
  distributorLogo,
  pageNoEmailVerificationMessage,
  step2Page,
  step2PageNoEmailVerificationMessage,
  stepTitle,
  step,
  distributorHeaderWrapper,
  chooseDistributorInfo,
};
